<script setup lang="ts">
import { ParagraphAnchors, FieldParagraphAnchorsFieldItems, Maybe, ParagraphAnchor } from '~/api/graphql/generated'

export interface Anchor {
  id: string
  title: string
}

const props = defineProps<{
  entity?: ParagraphAnchors
  extra?: Anchor[]
}>()

const items = [
  ...((props.entity as ParagraphAnchors)?.fieldItems
    ?.map((item: Maybe<FieldParagraphAnchorsFieldItems>) => item && (item.entity as ParagraphAnchor))
    .filter(Boolean)
    .map(
      (item) =>
        ({
          id: item.fieldId!,
          title: item.fieldTitle!,
        }) as Anchor,
    ) || []),
  ...(props.extra ?? []),
]
</script>

<template>
  <Section v-animate="{ cluster: true }">
    <div class="t-10 t-o-1 tl-12 tl-o-0 ds-10 ds-o-1">
      <h2 v-animate="{ animation: 'vertical-reveal' }">{{ $t('table of contents') }}</h2>
      <ul class="anchors-list cul">
        <li v-for="{ id, title } in items" :key="id" v-animate="{ animation: 'vertical-reveal' }" class="anchor">
          <h5>
            <NuxtLink :class="['anchor-link']" :to="{ hash: `#${id}` }">
              {{ title }}
              <Icon name="arrow-bend-right-down-large" />
            </NuxtLink>
          </h5>
        </li>
      </ul>
    </div>
  </Section>
</template>

<style lang="scss" scoped>
.anchors-list {
  position: relative;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(272px, 1fr));
  gap: 0 36px;
}
.anchor {
  height: 100%;

  h5 {
    margin-bottom: 0;
  }

  &-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    &:hover {
      color: var(--c-blue-rollover);
    }

    @include fluid(padding-block, 16px, 27px);
    border-bottom: 1px solid var(--c-line-light);
  }
}
</style>
